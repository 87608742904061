<template>
  <div class="w-full">
    <vx-card
      :class="
        $atividades.permissoes.includes(8)
          ? 'os overflow-hidden'
          : 'sem-autorizacao overflow-hidden'
      "
      @click="$atividades.permissoes.includes(8) ? openModal() : ''"
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  icon="phone_missed"
                  size="30px"
                  color="success"
                  class="img"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">CONFIRMAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'CONFIRMAR ' + item.nomeCliente" :active.sync="osModal">
      <vs-row
        vs-type="flex"
        vs-align="center"
        id="concluir"
        class="vs-con-loading__container"
      >
        <VuePerfectScrollbar
          class="concluir-pos-venda-scrollbar"
          :settings="settings"
        >
          <vs-divider class="p-0 m-0 mb-3 font-bold"
            >Pesquisa de Satisfação</vs-divider
          >
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            v-for="(item, index) of perguntas"
            :key="index"
          >
            <div class="px-3 w-full">
              <vs-row
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span style="text-align: justify">
                  <b>{{ index + 1 }}°.</b>
                  {{ item.texto }}
                </span>
              </vs-row>
              <vs-row
                vs-w="12"
                vs-type="flex"
                class="mt-5"
                vs-justify="center"
                vs-align="center"
              >
                <vs-radio
                  class="mx-2 pb-2"
                  color="success"
                  v-if="item.label1"
                  marked
                  v-model="pesquisa_satisfacao['resp' + (index + 1)]"
                  :vs-name="'pergunta' + index"
                  vs-value="1"
                  >{{ item.label1 }}</vs-radio
                >
                <vs-radio
                  class="mx-2 pb-2"
                  color="success"
                  v-if="item.label2"
                  marked
                  v-model="pesquisa_satisfacao['resp' + (index + 1)]"
                  :vs-name="'pergunta' + index"
                  vs-value="2"
                  >{{ item.label2 }}</vs-radio
                >
                <vs-radio
                  class="mx-2 pb-2"
                  v-if="item.label3"
                  color="success"
                  marked
                  v-model="pesquisa_satisfacao['resp' + (index + 1)]"
                  :vs-name="'pergunta' + index"
                  vs-value="3"
                  >{{ item.label3 }}</vs-radio
                >
                <vs-radio
                  class="mx-2 pb-2"
                  color="success"
                  marked
                  v-if="item.label4"
                  v-model="pesquisa_satisfacao['resp' + (index + 1)]"
                  :vs-name="'pergunta' + index"
                  vs-value="4"
                  >{{ item.label4 }}</vs-radio
                >
              </vs-row>
              <vs-divider class="p-0 m-0 mb-3 font-bold"></vs-divider>
            </div>
          </vs-col>

          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="p-3"
          >
            <div class="w-full p-0">
              <vs-textarea
                v-model="obs"
                class="p-0 m-0"
                name="obs"
                label="Observações"
              />
            </div>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="p-2"
          >
            <vx-card
              class="osConf overflow-hidden"
              no-shadow
              @click="concluirPosVenda()"
            >
              <template slot="no-body">
                <div class="item-details px-2">
                  <div class="my-1">
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      class="p-1"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span style="font-size: 20px">CONFIRMAR PÓS-VENDA</span>
                    </vs-col>
                  </div>
                </div>
              </template>
            </vx-card>
          </vs-col>
        </VuePerfectScrollbar>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      osModal: false,
      pesquisa_satisfacao: { id_contrato: null, id_cliente: null, resp1: '0', resp2: '0', resp3: '0', resp4: '0' },
      perguntas: [],
      obs: "",
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      }
    }
  },
  methods: {
    async concluirPosVenda () {
      await this.$vs.loading({ container: "#concluir", scale: 0.6 })
      try {
        this.pesquisa_satisfacao.id_contrato = this.item.id_contrato
        this.pesquisa_satisfacao.id_cliente = this.item.id_cliente
        const res = await this.$http.put(`os/` + this.item.id,
          {
            obs_pos_venda: this.obs,
            pos_venda: 1,
          })
        const assunto = await this.logAssunto(res)
        this.pesquisa_satisfacao.id_assunto = assunto.id
        await this.$http.post(`pesquisa_satisfacao_resultado`, this.pesquisa_satisfacao)
        await this.close()
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")
        await this.$vs.loading.close("#concluir > .con-vs-loading")
      }
    },
    async logAssunto (res) {
      try {
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: 'atualizar',
        //   setor: 'comercial',
        //   ip: window.localStorage.getItem("ip"),
        //   texto: 'Conclusão do pós-venda com o serviço N°' + res.id
        // }
        // await this.$logger(logData)
        const assunto = await this.$http.post("assuntoCliente", {
          id_assuntos: 19,
          id_cliente: this.item.id_cliente,
          id_contrato: this.item.id_contrato,
          id_colaborador: window.localStorage.getItem("id"),
          id_estagio: 3,
          status: 2,
          tipo: 5,
          data_marcada: this.$formartData.dataFiltro(),
          obs: this.obs
        })
        return assunto
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async close () {
      this.obs = ""
      this.osModal = await false
    },
    async getPerguntas () {
      try {
        this.perguntas = await this.$http.get(
          `pesquisa_satisfacao`
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async openModal () {
      this.osModal = true
      await this.getPerguntas()
    }
  },
  components: {
    VuePerfectScrollbar
  }
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #00bba2;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
.concluir-pos-venda-scrollbar {
  max-height: 80vh;
}
</style>
