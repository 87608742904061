var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('vx-card',{staticClass:"contactar overflow-hidden",on:{"click":function($event){(_vm.contatoSelected = {}),
        (_vm.contatosCliente = []),
        _vm.getContatos(),
        (_vm.osModal = true)}}},[_c('template',{slot:"no-body"},[_c('div',{staticClass:"item-details px-2"},[_c('div',{staticClass:"my-1"},[_c('vs-col',{staticClass:"p-5",attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('span',[_c('vs-icon',{attrs:{"icon":"contacts","size":"30px","color":"primary"}})],1)]),_c('vs-col',{attrs:{"vs-w":"12","vs-type":"flex","vs-justify":"center","vs-align":"center"}},[_c('span',{staticClass:"py-2",staticStyle:{"font-size":"20px"}},[_vm._v("CONTACTAR")])])],1)])])],2),_c('vs-popup',{attrs:{"title":'CONTATOS: ' + _vm.item.nomeCliente,"active":_vm.osModal},on:{"update:active":function($event){_vm.osModal=$event}}},[_c('div',{staticClass:"vs-con-loading__container w-full",attrs:{"id":"contactar"}},[(!_vm.contatosCliente.length > 0)?_c('div',[_c('div',{staticClass:"con-colors mt-10 mb-20"},[_c('ul',[_c('span',{staticClass:"px-3 pt-2",staticStyle:{"color":"grey !important","font-weight":"1000 !important"}},[_c('vs-icon',{attrs:{"size":"large","icon":"sentiment_dissatisfied"}})],1)])])]):_c('div',[_c('vs-row',{staticClass:"mb-3",attrs:{"type":"flex","vs-justify":"flex-start"}},_vm._l((_vm.contatosCliente),function(contato,index){return _c('vs-col',{key:index,staticClass:"p-1",attrs:{"vs-type":"flex","vs-justify":"flex-start","vs-align":"flex-start","vs-w":"6"}},[_c('vx-card',{class:contato.id == _vm.contatoSelected.id
                  ? 'active-contato'
                  : contato.lixeira > 0
                  ? 'deleted-contato'
                  : 'contato',attrs:{"no-shadow":""},on:{"click":function($event){contato.lixeira > 0 ? '' : _vm.selectContato(contato)}}},[_c('vs-row',{staticClass:"mt-2"},[_c('label',{staticClass:"font-semibold m-0 p-0",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(contato.nome)+" "+_vm._s(contato.lixeira)+" ")])]),_c('vs-row',{staticClass:"mb-2"},[_c('label',{staticClass:"m-0 p-0",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(contato.contato))])])],1)],1)}),1),_c('vs-button',{staticClass:"w-full mt-3",attrs:{"color":"success","size":"sm","type":"relief","disabled":_vm.contatoSelected.contato == null || _vm.contatoSelected.tipo == 1},on:{"click":function($event){return _vm.contactar()}}},[_vm._v("Contactar")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }