<template>
  <div>
    <div>
      <vs-row
        vs-type="flex"
        class="mb-4"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
          <h4 style="color: #b4aa99">Pós-Venda</h4>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="8"
        >
          <vs-checkbox
            color="success"
            class="mb-2 mx-3"
            v-model="filtro.tipo"
            :disabled="blockButton"
            >Serviços Ímpares</vs-checkbox
          >
          <vs-input
            color="dark"
            class="mx-1"
            size="small"
            type="text"
            placeholder
            label="Busca"
            v-model="filtro.busca"
          ></vs-input>
          <div>
            <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
              >Vendedor</label
            >
            <br />
            <el-select
              size="small"
              filterable
              clearable
              v-model="filtro.vendedor"
              class="mx-1 mt-2"
            >
              <el-option
                v-for="vendedor in vendedores"
                :value="vendedor.id"
                :label="vendedor.nome"
                :key="vendedor.id"
              ></el-option>
            </el-select>
          </div>

          <vs-input
            size="small"
            class="mx-2"
            type="date"
            label="Data De"
            v-model="filtro.dataDe"
          ></vs-input>
          <vs-input
            size="small"
            class="mx-2"
            type="date"
            label="Data Ate"
            v-model="filtro.dataAte"
          ></vs-input>
          <el-button
            class="mx-2"
            size="small"
            plain
            :disabled="blockButton"
            type="primary"
            @click="filtrar()"
            >Filtrar</el-button
          >
        </vs-col>
      </vs-row>
    </div>
    <vs-row vs-type="flex" vs-justify="flex-start">
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    PARA ACOMPANHAR: {{ osAcompanhar.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-pos-venda"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="osAcompanhar" class="vs-con-loading__container">
              <div v-if="!osAcompanhar.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div v-for="data in osAcompanhar" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              vs-w="12"
                              class="mt-1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Contrato N°
                                  <a
                                    target="_blank"
                                    :href="'contrato?id=' + data.id_contrato"
                                    class="font-bold"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}</a
                                  >
                                  - Serviço N°
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Cliente:
                                  <b>{{ data.nomeCliente }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Data:
                                  <b>
                                    {{
                                      data.pos_venda == 2
                                        ? data.data_remarcacao_pos_venda
                                        : data.data_execucao
                                          | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="
                                  data.obs_pos_venda != null || data.obs != null
                                "
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_pos_venda != null
                                        ? data.obs_pos_venda
                                        : data.obs
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="data.telefone != null"
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Telefone:
                                  <b>{{ data.telefone }}</b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #00bba2">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    CONCLUÍDOS: {{ osConcluidos.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-pos-venda"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="osConcluidos" class="vs-con-loading__container">
              <div v-if="!osConcluidos.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div v-for="data in osConcluidos" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              class="mt-1"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div class="w-full">
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Contrato N°
                                  <a
                                    target="_blank"
                                    :href="'contrato?id=' + data.id_contrato"
                                    class="font-bold"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}</a
                                  >
                                  - Serviço N°
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Cliente
                                  <b>{{ data.nomeCliente }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Data:
                                  <b>
                                    {{
                                      data.pos_venda == 2
                                        ? data.data_remarcacao_pos_venda
                                        : data.data_execucao
                                          | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="
                                  data.obs_pos_venda != null || data.obs != null
                                "
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_pos_venda != null
                                        ? data.obs_pos_venda
                                        : data.obs
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="data.telefone != null"
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Telefone:
                                  <b>{{ data.telefone }}</b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #f44336">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    REMARCADOS: {{ osRemarcados.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-pos-venda"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="osRemarcados" class="vs-con-loading__container">
              <div v-if="!osRemarcados.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div v-for="data in osRemarcados" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              vs-w="12"
                              class="mt-1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div class="w-full">
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Contrato N°
                                  <a
                                    target="_blank"
                                    :href="'contrato?id=' + data.id_contrato"
                                    class="font-bold"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}</a
                                  >
                                  - Serviço N°
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Cliente
                                  <b>{{ data.nomeCliente }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Data:
                                  <b>
                                    {{
                                      data.data_remarcacao_pos_venda
                                        | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="
                                  data.obs_pos_venda != null || data.obs != null
                                "
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_pos_venda != null
                                        ? data.obs_pos_venda
                                        : data.obs
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                v-if="data.telefone != null"
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Telefone:
                                  <b>{{ data.telefone }}</b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="1"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="flex-start"
                          >
                            <Badge
                              v-if="data.nomeZona != null"
                              :text="data.nomeZona"
                              color="orange"
                            ></Badge>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
    </vs-row>
    <vs-popup
      class
      :title="'ACOMPANHAMENTO: ' + selected.nomeCliente"
      :active.sync="iniciarModal"
    >
      <vs-row
        vs-type="flex"
        vs-align="center"
        class="vs-con-loading__container"
        id="loading"
      >
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <ConcluirPosVenda
            @update="close()"
            :item="selected"
          ></ConcluirPosVenda>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <ReagendarPosVenda
            @update="close()"
            :item="selected"
          ></ReagendarPosVenda>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalContactar
            @update="close()"
            :item="selected"
            tipo="pos_venda"
          ></modalContactar>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import ConcluirPosVenda from "./components/concluirPosVenda";
import ReagendarPosVenda from "./components/reagendarPosVenda";
import VisualizarCliente from "./../cliente/visualizar.vue";
import modalContactar from "./../confirmacao/components/contactarModal";

export default {
  data() {
    return {
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        dataDe: null,
        dataAte: null,
      },
      vendedores: [],
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      cardClass: "os-card overflow-hidden",
      selected: [],
      iniciarModal: false,
      osAcompanhar: [],
      osConcluidos: [],
      osRemarcados: [],
      blockButton: false,
      atividades: ["4", "5", "6"],
    };
  },
  async mounted() {
    this.$vs.loading();
    await this.getOsPosVendaAcompanhar();
    await this.getOsPosVendaConcluido();
    await this.getOsPosVendaRemarcado();
    await this.getVendedores();
    this.$vs.loading.close();
  },
  methods: {
    async getOsPosVendaAcompanhar() {
      await this.$vs.loading({ container: "#osAcompanhar", scale: 0.6 });
      try {
        this.osAcompanhar = await this.$http.post(
          `getOsPosVendaAcompanhar`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osAcompanhar > .con-vs-loading");
    },
    async getOsPosVendaConcluido() {
      await this.$vs.loading({ container: "#osConcluidos", scale: 0.6 });
      try {
        this.osConcluidos = await this.$http.post(
          `getOsPosVendaConcluido`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osConcluidos > .con-vs-loading");
    },
    async getOsPosVendaRemarcado() {
      await this.$vs.loading({ container: "#osRemarcados", scale: 0.6 });
      try {
        this.osRemarcados = await this.$http.post(
          `getOsPosVendaRemarcado`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osRemarcados > .con-vs-loading");
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.post(
          `colaboradorAtividade`,
          this.atividades
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtrar() {
      this.blockButton = true;
      await this.getOsPosVendaAcompanhar();
      await this.getOsPosVendaConcluido();
      await this.getOsPosVendaRemarcado();
      this.blockButton = false;
    },
    async close() {
      this.iniciarModal = await false;
      await this.getOsPosVendaAcompanhar();
      await this.getOsPosVendaConcluido();
      await this.getOsPosVendaRemarcado();
    },
  },
  components: {
    ...components,
    ConcluirPosVenda,
    ReagendarPosVenda,
    VisualizarCliente,
    modalContactar,
  },
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.os-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-pos-venda {
  height: 70vh !important;
}
</style>
