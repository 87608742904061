<template>
  <div class="w-full">
    <vx-card
      class="contactar overflow-hidden"
      @click="
        (contatoSelected = {}),
          (contatosCliente = []),
          getContatos(),
          (osModal = true)
      "
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon icon="contacts" size="30px" color="primary"></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">CONTACTAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'CONTATOS: ' + item.nomeCliente" :active.sync="osModal">
      <div id="contactar" class="vs-con-loading__container w-full">
        <div v-if="!contatosCliente.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span
                class="px-3 pt-2"
                style="color: grey !important; font-weight: 1000 !important"
                ><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon
              ></span>
            </ul>
          </div>
        </div>
        <div v-else>
          <vs-row type="flex" class="mb-3" vs-justify="flex-start">
            <vs-col
              vs-type="flex"
              class="p-1"
              vs-justify="flex-start"
              vs-align="flex-start"
              :key="index"
              vs-w="6"
              v-for="(contato, index) in contatosCliente"
            >
              <vx-card
                no-shadow
                :class="
                  contato.id == contatoSelected.id
                    ? 'active-contato'
                    : contato.lixeira > 0
                    ? 'deleted-contato'
                    : 'contato'
                "
                @click="contato.lixeira > 0 ? '' : selectContato(contato)"
              >
                <!-- <vs-list-item
                  style="color: black"
                  :title="contato.nome"
                  :subtitle="contato.contato"
                ></vs-list-item> -->
                <vs-row class="mt-2">
                  <label style="font-size: 16px" class="font-semibold m-0 p-0">
                    {{ contato.nome }} {{ contato.lixeira }}
                  </label>
                </vs-row>
                <vs-row class="mb-2">
                  <label style="font-size: 14px" class="m-0 p-0">{{
                    contato.contato
                  }}</label>
                </vs-row>
              </vx-card>
            </vs-col>
          </vs-row>
          <vs-button
            class="w-full mt-3"
            color="success"
            size="sm"
            type="relief"
            :disabled="
              contatoSelected.contato == null || contatoSelected.tipo == 1
            "
            @click="contactar()"
            >Contactar</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
    },
    tipo: {
      required: true,
    },
  },
  data() {
    return {
      osModal: false,
      contatosCliente: [],
      contatoSelected: { id: null, contato: null, nome: null },
    };
  },
  methods: {
    async getContatos() {
      await this.$vs.loading({ container: "#contactar", scale: 0.6 });
      try {
        this.contatosCliente = await this.$http.get(
          "contatoCliente/" + this.item.id_cliente
        );
        let contatoPrincipal = {};
        contatoPrincipal.nome = this.item.nomeCliente;
        contatoPrincipal.contato = this.item.telefone;
        contatoPrincipal.tipo = 2;
        contatoPrincipal.id = 0;

        this.contatosCliente.unshift(contatoPrincipal);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#contactar > .con-vs-loading");
    },
    async selectContato(contato) {
      this.contatoSelected = Object.assign(contato);
    },
    async contactar() {
      let contato = this.contatoSelected.contato;
      contato =
        "+55" +
        (await this.$removerMascara.remove(this.contatoSelected.contato));

      if (contato.length > 13) {
        contato = contato.slice(0, 5) + contato.slice(6);
      }
      await this.$vs.loading({ container: "#contactar", scale: 0.6 })
      try {
        if (this.tipo == "os_confirmacao") {
          let texto =
            `Olá,${this.item.nomeCliente}!` +
            ` Gostaria de confirmar um serviço de ${this.item.controle}` +
            ` agendado conosco da EMOPS.` +
            `%0aLocal do serviço: ${this.item.logradouro}, ${this.item.nomeBairro}, ${this.item.numero}.` +
            `%0aDia e horário: ` +
            `${
              this.item.remarcado > 0
                ? this.item.data_remarcacao
                : this.item.data_competencia
            }` +
            ` ${
              this.item.hora_marcada ? this.item.hora_marcada : this.item.turno
            }.` +
            `%0aConfirma o serviço? `;

          const res = await this.$http.post("whatsapp", {
            numero: contato,
            texto: texto,
          });
          window.open(res);
        } else {
          if (this.tipo == "pos_venda") {
            let texto =
              `Olá, ${this.item.nomeCliente}. %0a` +
              "%0a❤️VOCÊ É MUITO IMPORTANTE para nós do grupo EMOPS! %0a" +
              "%0a🤝 O seu feedback é primordial para a melhoria e qualidade dos nossos serviços. %0a" +
              "%0aPor gentileza, responda a pergunta abaixo:%0a" +
              `%0a✔️ Em uma escala de 1 a 10, qual a probabilidade de você recomendar nossa empresa (serviço ou produto) para um colega, parente ou amigo? %0a`

            const res = await this.$http.post("whatsapp", {
              numero: contato,
              texto: texto,
            });
            window.open(res)
          }
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#contactar > .con-vs-loading");
    },
  },
  async mounted() {},
};
</script>
<style scoped lang="scss">
.contactar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffca00;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}

.active-contato {
  background-color: #ffca00;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
  color: black !important;
}
.contato {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  background: #eeeeee;
  cursor: pointer;
  color: black !important;
}
.contato:hover {
  transition: 0.1s;
  background: #fff;
  border-width: 1px;
  border-color: black;
  transform: translateY(-3px);
}
.deleted-contato {
  cursor: not-allowed;
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  background: #c0564b;
  color: white !important;
}
</style>
