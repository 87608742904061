<template>
  <div class="w-full">
    <vx-card :class="$atividades.permissoes.includes(8) ? 'os overflow-hidden' : 'sem-autorizacao overflow-hidden'" @click="$atividades.permissoes.includes(8) ? osModal = true : ''">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
              <span>
                <vs-icon icon="phone_missed" size="30px" color="#f44336" class="img"></vs-icon>
              </span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class>
              <span style="font-size: 20px" class="py-2">REMARCAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'REMARCAR ' + item.nomeCliente" :active.sync="osModal">
      <vs-row vs-type="flex" vs-align="center" id="remarcar" class="vs-con-loading__container">
        <vs-col vs-w="7" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <div class="w-full p-0">
          <vs-textarea v-model="os" class="p-0 m-0" name="obs" label="Motivo da alteração" v-validate="'required'" />
          <span
              class="text-danger mb-2 text-sm"
              v-show="errors.has('obs')">
            {{$validators.empty}}</span>
          </div>
        </vs-col>
        <vs-col vs-w="5" vs-type="flex" vs-justify="center" vs-align="center" class>
          <div class="w-full p-0">
          <vs-input
            v-model="data_remarcacao"
            label="Data"
            type="date"
            v-validate="'required'"
            name="data"
            class="inputx w-full px-3 pt-3"
          />
          <span
              class="text-danger mx-3 mb-2 text-sm"
              v-show="errors.has('data')">
            {{$validators.empty}}</span>
          </div>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="center" vs-align="center" class="p-2">
          <vx-card class="osConf overflow-hidden" no-shadow @click="submitForm()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col vs-w="12" vs-type="flex" class="p-1" vs-justify="center" vs-align="center">
                    <span style="font-size: 20px">CONFIRMAR REMARCAÇÃO</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      osModal: false,
      os: "",
      data_remarcacao: "",
    };
  },
  methods: {
    async PosVendaRemarcacao() {
        await this.$vs.loading({ container: "#remarcar", scale: 0.6 });
        try {
          const res = await this.$http.put(`os/` + this.item.id,
            {
              obs_pos_venda: this.os,
              pos_venda: 2,
             data_remarcacao_pos_venda: this.data_remarcacao
            });
          // let logData = {
          //   id_colaborador: localStorage.getItem("id"),
          //   funcao: 'atualizar',
          //   setor: 'comercial',
          //   ip: window.localStorage.getItem("ip"),
          //   texto: 'Remarcação da pós-venda N°' + res.id}
          // await this.$logger(logData)
          this.close();
          await this.$vs.notify(this.$notify.Success);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          await this.$emit("update");
          await this.$vs.loading.close("#remarcar > .con-vs-loading");
      }
    },
    async submitForm() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.PosVendaRemarcacao()
          } else {
            this.$vs.notify({
              title:'Preencha todos os campos',
              text:'Os campos não devem ser vazios!',
              color:'danger'
            })
            reject("error");
          }
        })
      })
    },
    async close() {
      this.os = ""
      this.data_remarcacao = ""
      this.osModal = await false;
    },
  }
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
    background-color: #aaa;
    color: white;
    .img {
     color: white !important;
    }
}
</style>
